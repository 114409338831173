@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    /* background-color: rgb(210, 205, 205); */
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: medium;
    font-style: normal;
}

/* Hide scrollbar for Webkit browsers */
.overflow-y-auto::-webkit-scrollbar {
    display: none;
    /* Hide scrollbar */
}

/* Hide scrollbar for Firefox */
.overflow-y-auto {
    scrollbar-width: none;
    /* Hide scrollbar for Firefox */
}